import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer style={styles.footerContainer}>
      <div style={styles.contactSection}>
        <p style={styles.contactText}>
          Contact me:{" "}
          <a href="mailto:developer@jaysmart.dev" style={styles.emailLink}>
            developer@jaysmart.dev
          </a>
        </p>
      </div>
      <div style={styles.linksSection}>
        <a
          href="https://wa.me/message/IWKFOX6HVTO7C1"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.link}
        >
          <FontAwesomeIcon icon={faWhatsapp} />
        </a>
        <a
          href="https://www.linkedin.com/in/anutaajovwoke"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.link}
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a
          href="https://github.com/JaySmart001"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.link}
        >
          <FontAwesomeIcon icon={faGithub} />
        </a>
      </div>
    </footer>
  );
};

const styles = {
  footerContainer: {
    backgroundColor: "#282c34",
    color: "#fff",
    textAlign: "center",
    padding: "20px 10px",
    marginTop: "30px",
  },
  contactSection: {
    marginBottom: "15px",
  },
  contactText: {
    margin: 0,
    padding: 0,
  },
  emailLink: {
    color: "#4da6ff",
    textDecoration: "none",
  },
  linksSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  link: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: "40px",
    height: "40px",
    margin: "0 10px",
    borderRadius: "50%",
    color: "#fff",
    textDecoration: "none",
    fontSize: "24px",
    // Adjust the background color to fit the icon color scheme if needed
    backgroundColor: "transparent",
  },
};

export default Footer;
