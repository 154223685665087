import React, { forwardRef } from "react";

const Drawer = forwardRef(
  ({ isOpen, scrollToFooter, closeDrawer, scrollToServices }, ref) => {
    return (
      <div
        ref={ref}
        style={{
          position: "fixed",
          left: isOpen ? "0" : "-100%",
          top: "0",
          width: "250px",
          height: "100%",
          backgroundColor: "white",
          transition: "left 0.3s ease",
          padding: "20px",
          paddingTop: "60px",
          boxSizing: "border-box",
          zIndex: 1,
        }}
      >
        <ul style={{ listStyleType: "none", padding: "0" }}>
          {/* Add cursor: 'pointer' to indicate it's clickable */}
          <li
            style={{ margin: "20px 0", cursor: "pointer" }}
            onClick={() => {
              scrollToFooter();
              closeDrawer();
            }}
          >
            CONTACT US
          </li>
          <li
            style={{ margin: "20px 0", cursor: "pointer" }}
            onClick={() => {
              scrollToServices();
              closeDrawer();
            }}
          >
            OUR SERVICES
          </li>{" "}
        </ul>
      </div>
    );
  }
);

export default Drawer;
