import React, { useState, useEffect, useRef } from "react";
import Navbar from "./components/Navbar";
import Drawer from "./components/Drawer";
import FirstSection from "./components/FirstSection"; // Adjust the import path as necessary
import AboutUs from "./components/AboutUs";
import ProjectsSection from "./components/ProjectsSection"; // Adjust the import path as necessary
import ServicesSection from "./components/ServicesSection"; // Adjust the import path as necessary
import Footer from "./components/FooterComponent"; // Adjust the import path as necessary

function App() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const drawerRef = useRef(null); // Create a ref for the drawer
  const footerRef = useRef(null); // Create a ref for the footer
  const servicesSectionRef = useRef(null);

  const toggleDrawer = () => {
    console.log("Toggling drawer");

    setIsDrawerOpen((prevIsOpen) => !prevIsOpen);
  };

  const scrollToFooter = () => {
    footerRef.current?.scrollIntoView({ behavior: "smooth" });
    setIsDrawerOpen(false); // Optionally close the drawer here or in Drawer component
  };

  const scrollToServices = () => {
    servicesSectionRef.current?.scrollIntoView({ behavior: "smooth" });
    setIsDrawerOpen(false); // Close the drawer
  };

  const closeDrawer = () => {
    console.log("Closing drawer");

    setIsDrawerOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      console.log("Click outside detected");
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        isDrawerOpen
      ) {
        console.log("Outside click is closing the drawer");
        setIsDrawerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [drawerRef, isDrawerOpen]);

  return (
    <div>
      <Navbar
        toggleDrawer={toggleDrawer}
        isOpen={isDrawerOpen}
        closeDrawer={closeDrawer}
      />
      <Drawer
        isOpen={isDrawerOpen}
        ref={drawerRef}
        scrollToFooter={scrollToFooter}
        closeDrawer={closeDrawer}
        scrollToServices={scrollToServices} // Pass the new function as a prop
      />
      <FirstSection />
      <div ref={servicesSectionRef}>
        <ServicesSection />
      </div>{" "}
      <ProjectsSection />
      <AboutUs />
      <div ref={footerRef}>
        <Footer />
      </div>
    </div>
  );
}

export default App;
