import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const Navbar = ({ toggleDrawer, isOpen, closeDrawer }) => {
  return (
    <>
      <style>
        {`
          .menu-icon {
            position: absolute;
            left: 10px;
            cursor: pointer;
            fontSize: 24px;
            transition: transform 0.3s ease;
            transform: ${isOpen ? "rotate(180deg)" : "rotate(0deg)"};
          }
          /* Hide the menu icon on desktop */
          @media (min-width: 768px) {
            .menu-icon {
              display: none;
            }
          }
        `}
      </style>
      <nav
        style={{
          width: "100%",
          padding: "10px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative", // Required for z-index to work
          zIndex: 2, // Higher z-index
        }}
      >
        <FontAwesomeIcon
          icon={isOpen ? faTimes : faBars}
          onClick={(event) => {
            event.stopPropagation();
            isOpen ? closeDrawer() : toggleDrawer();
          }}
          className="menu-icon"
          fontSize={24}
        />
        <img
          src={process.env.PUBLIC_URL + "/logo.png"}
          alt="Logo"
          style={{ height: "50px" }}
        />
      </nav>
    </>
  );
};

export default Navbar;
