import React from "react";

const AboutUs = () => {
  return (
    <>
      <style>
        {`
           .begin-button {
            margin-top: 20px;
            padding: 10px 20px;
            font-size: 1em;
            color: #fff;
            background-color: #1a2143;
            border: 2px solid transparent; /* Initialize border to maintain layout */
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s, color 0.3s;
            text-decoration: none; /* Remove underline from links */
            display: inline-block; /* Necessary for proper spacing and alignment */
          }

          .begin-button:hover {
            background-color: #fff;
            color: #1a2143;
            border-color: #1a2143;
          }
        `}
      </style>
      <section style={styles.container}>
        <h2 style={styles.header}>About Us</h2>
        <p style={styles.paragraph}>
          At JaySmart Technologies, we're driven by the passion to create
          stunning, functional mobile and web applications that solve real-world
          problems. Founded in 2018, our team of dedicated developers and
          designers has been at the forefront of bringing innovative solutions
          to our clients, transforming their ideas into reality.
        </p>
        <p style={styles.paragraph}>
          We believe in building products that not only meet the current
          technological trends but also set new standards in the tech industry.
          Our approach combines creativity, expertise, and a deep understanding
          of our clients' needs, ensuring each project exceeds expectations.
        </p>
        <div style={styles.teamPhotoContainer}>
          {/* <img src="url_to_team_photo.jpg" alt="Our Team" style={styles.teamPhoto}/> */}
        </div>
        <p style={styles.paragraph}>
          Ready to bring your vision to life? Let’s create something
          extraordinary together.
        </p>
        {/* Button added below */}
        <a
          href="https://wa.me/message/IWKFOX6HVTO7C1"
          target="_blank"
          rel="noopener noreferrer"
          className="begin-button"
        >
          Begin
        </a>
      </section>
    </>
  );
};

const styles = {
  container: {
    textAlign: "center",
    padding: "50px 20px",
    backgroundColor: "#fff",
  },
  header: {
    fontSize: "2em",
    marginBottom: "20px",
  },
  paragraph: {
    fontSize: "1em",
    lineHeight: "1.6",
    margin: "20px auto",
    maxWidth: "800px",
  },
  teamPhotoContainer: {
    margin: "30px 0",
  },
  // Removed the inline button styles to use the <style> tag
  teamPhoto: {
    maxWidth: "100%",
    height: "auto",
    borderRadius: "10px",
  },
};

export default AboutUs;
