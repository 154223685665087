// FirstSection.js
import React from "react";

const FirstSection = () => {
  return (
    <section style={sectionStyle}>
      <h1 style={headerStyle}>Think, Design, Build, Deploy</h1>
      <p style={paragraphStyle}>
        Transforming ideas into reality through innovative development.
      </p>
      <div style={processContainerStyle}>
        {processSteps.map((step, index) => (
          <div key={index} style={processStepStyle}>
            <h2 style={processStepTitleStyle}>{step.title}</h2>
            <p style={processStepDescriptionStyle}>{step.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

// Styles
const sectionStyle = {
  textAlign: "center",
  padding: "50px 20px",
  backgroundColor: "#fff", // Light background color
};

const headerStyle = {
  margin: "0 0 20px 0",
  color: "#333", // Dark text color for contrast
};

const paragraphStyle = {
  margin: "0 0 30px 0",
  color: "#666", // Medium-dark text color
};

const processContainerStyle = {
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap", // Ensure responsiveness
  gap: "20px", // Space between elements
};

const processStepStyle = {
  minWidth: "200px", // Minimum width for each step
  margin: "10px",
  padding: "20px",
  backgroundColor: "white", // Background color for each step
  borderRadius: "5px", // Rounded corners
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Subtle shadow
};

const processStepTitleStyle = {
  margin: "0 0 10px 0",
};

const processStepDescriptionStyle = {
  margin: "0",
};

// Data for the process steps
const processSteps = [
  { title: "Think", description: "Ideation and conceptualization." },
  { title: "Design", description: "Creating user-centered designs." },
  { title: "Build", description: "Turning designs into functional products." },
  { title: "Deploy", description: "Launching the product to the world." },
];

export default FirstSection;
