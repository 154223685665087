import React from "react";

const ServicesSection = () => {
  return (
    <>
      <style>
        {`
          .service-grid {
            display: grid;
            grid-template-columns: 1fr; /* Single-column layout for mobile */
            gap: 20px;
          }

          @media (min-width: 769px) { /* Layout changes for screens wider than 768px */
            
          
          .service-grid {
              grid-template-columns: 1fr 2fr; /* Two-column layout for larger screens */
            }
            .service-title {
              font-weight: bold; /* Title text is bold on larger screens */
              grid-column: 1 / -1; /* Ensures the title spans across both columns */

            }
            .tools-wrapper {
              grid-column: 2; /* Positions the tools at the start of the second column */
            }
          }
        `}
      </style>
      <section className="service-section" style={styles.container}>
        <h2 style={styles.header}>Our Services</h2>

        {/* Service 1: Mobile App Development */}
        <div className="service-grid" style={{ margin: "20px 0" }}>
          <h3
            style={{
              fontSize: "1.5em",
              color: "#1a2143",
              marginBottom: "0.5rem",
              fontSize: "1.5em",
            }}
          >
            Mobile App Development
          </h3>
          <div>
            <p style={{ fontSize: "1em", lineHeight: "1.6" }}>
              Creating high-quality, performant mobile applications with a focus
              on both iOS and Android platforms. Our primary stack includes
              Flutter/Dart for cross-platform development, ensuring a seamless
              user experience across devices.
            </p>
            <div style={{ marginTop: "10px" }}>
              {/* Tools */}
              <span style={styles.tool}>Flutter</span>
              <span style={styles.tool}>Dart</span>
              <span style={styles.tool}>Firebase</span>
              <span style={styles.tool}>Rest APIs</span>
            </div>
          </div>
        </div>

        {/* Service 2: UI/UX Design */}
        <div className="service-grid" style={{ margin: "20px 0" }}>
          <h3
            style={{
              fontSize: "1.5em",
              color: "#1a2143",
              marginBottom: "0.5rem",
            }}
          >
            UI/UX Design
          </h3>
          <div>
            <p style={{ fontSize: "1em", lineHeight: "1.6" }}>
              Designing intuitive and engaging user interfaces and experiences.
              From conceptualization to final design, we prioritize user-centric
              design principles to create solutions that are not only visually
              appealing but also user-friendly.
            </p>
            <div style={{ marginTop: "10px" }}>
              {/* Tools */}
              <span style={styles.tool}>Adobe XD</span>
              <span style={styles.tool}>Illustrator</span>
              <span style={styles.tool}>Figma</span>
            </div>
          </div>
        </div>

        {/* Service 3: Web Development */}
        <div className="service-grid" style={{ margin: "20px 0" }}>
          <h3
            style={{
              fontSize: "1.5em",
              color: "#1a2143",
              marginBottom: "0.5rem",
            }}
          >
            Web Development
          </h3>
          <div>
            <p style={{ fontSize: "1em", lineHeight: "1.6" }}>
              Building robust web applications and websites using the latest
              technologies. Our expertise in the MERN stack allows us to deliver
              scalable, secure, and dynamic web solutions tailored to your
              business needs.
            </p>
            <div style={{ marginTop: "10px" }}>
              {/* Tools */}
              <span style={styles.tool}>React</span>
              <span style={styles.tool}>MongoDB</span>
              <span style={styles.tool}>Express</span>
              <span style={styles.tool}>Node.js</span>
              <span style={styles.tool}>GraphQL</span>
            </div>
          </div>
        </div>

        {/* Service 4: Version Control */}
        <div className="service-grid" style={{ margin: "20px 0" }}>
          <h3
            style={{
              fontSize: "1.5em",
              color: "#1a2143",
              marginBottom: "0.5rem",
            }}
          >
            Version Control
          </h3>
          <div>
            <p style={{ fontSize: "1em", lineHeight: "1.6" }}>
              Ensuring code integrity and facilitating collaboration among
              development teams. We leverage Git and other version control tools
              to manage code changes and maintain project history effectively.
            </p>
            <div style={{ marginTop: "10px" }}>
              {/* Tools */}
              <span style={styles.tool}>Git</span>
              <span style={styles.tool}>GitHub</span>
              <span style={styles.tool}>GitLab</span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const styles = {
  container: {
    padding: "2rem",
    backgroundColor: "#f0f0f0",
  },
  header: {
    textAlign: "center",
    margin: "1rem 0",
    fontSize: "2em",
  },
  serviceGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr", // Adjust based on your preference
    alignItems: "start",
    gap: "20px", // Space between the title and content
    margin: "20px 0",
  },
  serviceTitle: {
    fontSize: "1em",
    color: "#1a2143",
    marginBottom: "0.5rem", // Adjust as needed
  },
  serviceContent: {
    // Styles for the content container (optional adjustments)
  },
  serviceDescription: {
    fontSize: "1em",
    lineHeight: "1.6",
  },
  toolsList: {
    marginTop: "10px",
  },
  tool: {
    display: "inline-block",
    background: "#1a2143",
    color: "#fff",
    padding: "5px 10px",
    borderRadius: "5px",
    margin: "5px",
    fontSize: "0.9em",
  },
};

export default ServicesSection;
