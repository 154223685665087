import React, { useState, useEffect, useMemo } from "react";

const ProjectsSection = () => {
  const projects = useMemo(
    () => [
      {
        name: "Audio Zic",
        description: "Showcasing Custom-Designed Audio Headsets",
        link: "http://example.com",
        images: [
          `${process.env.PUBLIC_URL}/images/audiozic-1.png`,
          `${process.env.PUBLIC_URL}/images/audiozic-2.png`,
          `${process.env.PUBLIC_URL}/images/audiozic-3.png`,
          // Add more image paths as needed
        ],
      },

      {
        name: "PPP",
        description: "A Telemedical App",
        link: "http://example.com",
        images: [
          `${process.env.PUBLIC_URL}/images/ppp-1.png`,
          `${process.env.PUBLIC_URL}/images/ppp-2.png`,
          `${process.env.PUBLIC_URL}/images/ppp-3.png`,
          `${process.env.PUBLIC_URL}/images/ppp-4.png`,
          // Add more image paths as needed
        ],
      },

      // Add other project details here
    ],
    []
  ); // Dependency array is empty, indicating this memoization should happen once

  const piideoImages = [
    `${process.env.PUBLIC_URL}/images/img-piideo-1.png`,
    `${process.env.PUBLIC_URL}/images/img-piideo-2.png`,
    `${process.env.PUBLIC_URL}/images/img-piideo-3.png`,
    `${process.env.PUBLIC_URL}/images/img-piideo-4.png`,
  ];

  // State to keep track of the current image index
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [audioZicImageIndex, setAudioZicImageIndex] = useState(0);
  const [pppImageIndex, setpppImageIndex] = useState(0);

  const audioZicImagesLength = useMemo(
    () => projects[0].images.length,
    [projects]
  );
  const pppImagesLength = useMemo(() => projects[1].images.length, [projects]);

  // Effect to cycle through "PIIDEO" images every 2 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex(
        (currentImageIndex) => (currentImageIndex + 1) % piideoImages.length
      );
    }, 3000); // Change image every 2 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [piideoImages.length]); // Added piideoImages.length to dependencies

  useEffect(() => {
    const audioZicIntervalId = setInterval(() => {
      setAudioZicImageIndex(
        (currentIndex) => (currentIndex + 1) % audioZicImagesLength
      );
    }, 3000); // Change image every 3 seconds for Audio Zic
    return () => clearInterval(audioZicIntervalId);
  }, [audioZicImagesLength]); // Use the memorized length here

  useEffect(() => {
    const pppIntervalId = setInterval(() => {
      setpppImageIndex((currentIndex) => (currentIndex + 1) % pppImagesLength);
    }, 3000); // Change image every 3 seconds for Audio Zic
    return () => clearInterval(pppIntervalId);
  }, [pppImagesLength]); // Use the memorized length here

  // Reusing the styles from AboutUs
  const styles = {
    header: {
      fontSize: "2em",
      marginBottom: "20px",
    },
    paragraph: {
      fontSize: "1em",
      lineHeight: "1.6",
      margin: "20px auto",
      maxWidth: "800px",
    },
    // Other styles as necessary
  };

  return (
    <>
      <style>
        {`
          .project-image {
            flex: 1; // Allows the image to fill the space available
            width: auto;
            max-width: 80%; // Ensures the image is not larger than its container
            max-height: 100px; // Limiting height to maintain a consistent size
            display: block;

            height: auto;
            margin: 0 auto 1rem auto; /* Center the image */
          }

          @media (min-width: 768px) {
            .project-image {
              max-width: 20%; /* Reduced size on desktop */
            }
            .projects-grid {
              display: grid;
              grid-template-columns: repeat(3, 1fr); /* 3 columns */
              gap: 2rem; /* Increased gap for more space between projects */
            }
            .project-container {
              /* Adjustments to the project container for larger view */
              padding: 2rem; /* Increase padding */
              /* Add any other adjustments to increase size or spacing */
            }
          }

          @media (max-width: 768px) {
            .project-image {
              width: 80%; /* Ensure the image takes the full width of its container */
              max-width: none; /* Override any max-width set for larger screens */
              height: auto; /* Maintain aspect ratio */
              object-fit: cover; /* Cover the area without stretching. Note: This may crop the image. */
              margin: 0 auto; /* Center the image */
              display: block; /* Ensure display is block for margin auto to work */
            }

            
            .project-content {
              flex-direction: column; // Stack items vertically on smaller screens
            }
          }

          .project {
            display: flex;
            align-items: center; // Aligns items vertically in the center
            margin-bottom: 2rem; // Adds space between project entries
          }

          .project-content {
            flex: 2; // Allows the text content to take up more space than the image
          }
        `}
      </style>
      <section
        style={{
          padding: "2rem",
          textAlign: "center",
        }}
      >
        <h2 style={styles.header}>Our Projects</h2>

        {/* Featured Project */}
        <div className="project" style={{ flexDirection: "column" }}>
          {" "}
          {/* Ensure this is column for mobile first */}
          <div className="project-content">
            {" "}
            {/* This will contain text and button */}
            <h3 style={styles.header}>PIIDEO (Recently Launched)</h3>
            <p style={styles.paragraph}>
              Features: Curated fashion showcases. Save and share your favorite
              pieces easily. Premium services in select cities, with more coming
              soon. Join the Movement: Piideo is more than an app—it's a
              celebration of African fashion.
            </p>
            <a
              href="https://play.google.com/store/apps/details?id=com.jaysmart.piideo&pcampaignid=web_share"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "inline-block",
                margin: "1rem 0",
                textDecoration: "none",
                color: "#1a2143",
                backgroundColor: "#fff",
                border: "2px solid #1a2143",
                padding: "0.5rem 1rem",
                borderRadius: "5px",
              }}
            >
              View Project
            </a>
          </div>
          <img
            className="project-image"
            src={piideoImages[currentImageIndex]}
            alt="PIIDEO Showcase"
          />
        </div>

        {/* Other Projects */}
        <div
          className="projects-grid"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
            gap: "1rem",
          }}
        >
          {projects.map((project, index) => (
            <div
              key={index}
              className="project-container"
              style={{
                marginTop: "25px",
                border: "1px solid #ddd",
                borderRadius: "5px",
                padding: "1rem",
              }}
            >
              <h4>{project.name}</h4>
              <p>{project.description}</p>
              <img
                className="project-image"
                src={
                  project.images[
                    project.name === "Audio Zic"
                      ? audioZicImageIndex
                      : project.name === "PPP"
                      ? pppImageIndex
                      : 0
                  ]
                }
                alt={project.name}
              />
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default ProjectsSection;
